import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import { Field } from "formik";
import SectionIntro from "@components/SectionIntro";
import GenericInput from "@components/fields/GenericInput";
import AddressLookup from "@components/fields/AddressLookup";
function PersonalDetails({ sectionIntro }) {
    const options = [
        { value: "1", label: "One" },
        { value: "2", label: "Two" },
        { value: "3", label: "Three" }
    ];
    /* Step 5: PersonalDetails */
    return (_jsxs("div", { className: "form__group", children: [_jsx(SectionIntro, { innerHtml: sectionIntro }), _jsx(Field, { name: "fullName", type: "text", placeholder: "Full name", component: GenericInput }), _jsx(Field, { name: "emailAddress", type: "email", placeholder: "Email address", component: GenericInput }), _jsx(Field, { name: "phone", type: "tel", placeholder: "Phone number", component: GenericInput }), _jsx(Field, { name: "address", placeholder: "Enter contact post code...", component: AddressLookup })] }));
}
PersonalDetails.defaultProps = {
    sectionIntro: null,
};
export default PersonalDetails;
