import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "preact/jsx-runtime";
import { ErrorMessage } from "formik";
import clsx from "clsx";
import sanitizeTitle from "@shared/sanitizeTitle";
function GenericInput(props) {
    const { field, label, hideLabel, id, type, placeholder, } = props;
    const { name } = field;
    const inputId = id !== null && id !== void 0 ? id : `input-${sanitizeTitle(name)}`;
    const labelClass = clsx("form__label", hideLabel && "sr-only");
    return (_jsxs(_Fragment, { children: [label && (_jsx("label", { htmlFor: inputId, className: labelClass, children: label })), _jsx("input", Object.assign({}, field, { type: type !== null && type !== void 0 ? type : "text", placeholder: placeholder, className: "form__control" })), _jsx(ErrorMessage, { component: "div", className: "form__error", name: name })] }));
}
GenericInput.defaultProps = {
    label: null,
    hideLabel: true,
};
export default GenericInput;
